import "./App.css";
import { Night } from "./components/night/Night";
import {
  useAuthenticator,
  withAuthenticator,
  View,
  Button,
  Card,
  Loader,
  Heading,
  Flex,
  IconChatBubbleOutline,
  Alert,
  Link,
  Badge,
} from "@aws-amplify/ui-react";
import Predictions from "@aws-amplify/predictions";
import {
  AmplifyS3Image,
  AmplifyS3ImagePicker,
  AmplifyS3Album,
} from "@aws-amplify/ui-react/legacy";
import { API, Auth, loadingBar, Storage, graphqlOperation } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { upload } from "@testing-library/user-event/dist/upload";
import scaleDown from "./uitls/scaledown";
import { createPicture } from "./graphql/mutations";

function App() {
  const [name, setName] = useState(null);
  const [key, setKey] = useState("profile.jpg");
  const [labels, setLabels] = useState([]);
  const a = useAuthenticator();
  useEffect(() => {
    Auth.currentUserInfo().then((u) => setName(u.attributes.name));
    API.post("nlp", "/predict", {
      body: {
        text: "check errors users are reporting from the chrome browser",
      },
    }).then((r) => console.log(r));
    console.log();
  }, []);

  const upload = async (file) => {
    if (!file) {
      return;
    }
    setKey(null);
    setLabels([]);
    //resize
    let resized = await scaleDown(file, 800);
    // let labels = await Predictions.identify({
    //   entities: {
    //     source: {
    //       file,
    //     },
    //   },
    // });
    let { id } = await Auth.currentUserInfo();
    console.log(id);
    let res = await Storage.put(`profile.jpg`, resized, {
      contentType: resized.type,
      level: "private",
    });
    let moderation = await API.get(
      "rekogitionmoderation1",
      `/moderate/${res.key}`
    );
    API.graphql(
      graphqlOperation(createPicture, {
        input: {
          userID: id,
          imageKey: res.key,
          labels: moderation,
        },
      })
    );
    setLabels(moderation);
    setKey(res.key);
  };

  return (
    <Night>
      <Card>
        <div>
          <h3> Hello {a.user.attributes.name}</h3>
        </div>
        <Link onClick={() => a.signOut()}>Logout</Link>
        <br />
        <div>
          {labels.map((l) => (
            <Badge key={l} variation="warning" style={{ margin: ".5rem" }}>
              {l}
            </Badge>
          ))}
        </div>
        {key ? (
          <AmplifyS3Image imgKey={key} level="private" />
        ) : (
          <Loader></Loader>
        )}
        <br />
        <input
          type="file"
          onChange={(e) => upload(e.target.files[0])}
          accept="image/jpg"
        />
      </Card>
    </Night>
  );
}

export default withAuthenticator(App, {
  components: {
    Footer: Night,
  },
  variation: "default",
});
