export default async function scaleDown(
  file,
  maxFixedSize = 800,
  canvasCtxCallback = null
) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      let image = new Image();
      image.src = reader.result;
      image.onload = async () => {
        let canvas = document.createElement("canvas");
        let rattio = image.width / image.height;
        canvas.width = maxFixedSize * Math.min(rattio, 1);
        canvas.height = maxFixedSize / Math.max(rattio, 1);
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        if (canvasCtxCallback) {
          canvasCtxCallback(ctx, canvas.width, canvas.height);
        }
        canvas.toBlob(
          (out) => {
            console.log(out.size);
            resolve(out);
          },
          "image/jpeg",
          0.9
        );
      };
    };
    reader.readAsDataURL(file);
  });
}
